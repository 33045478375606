<template>
  <div>
    <div class="lv_flexalign_center" style="margin-bottom: 15px">
      <el-date-picker
        v-model="params.time"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-input v-model="params.name" placeholder="搜索名称" />
      <el-cascader
        v-model="params.classifyId2"
        :options="labelList"
        :show-all-levels="false"
        :props="{
          children: 'child',
          value: 'id',
          label: 'name',
          checkStrictly: true,
          emitPath: false,
        }"
      ></el-cascader>
      <el-select v-model="params.status" placeholder="请选择">
        <el-option value="" label="全部状态" />
        <el-option :value="1" label="正常" />
        <el-option :value="2" label="下架" />
      </el-select>
      <el-button
        type="primary"
        @click="
          params.pageIndex = 1;
          getList();
        "
        >搜索</el-button
      >
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="标题">
        <template slot-scope="scope">
          <div class="ls_flexalign_center">
            <img
              :src="scope.row.coverImage | imgFormat"
              style="width: 40px; height: 40px; margin-right: 15px"
            />
            <div>{{ scope.row.name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="classifyName2" label="一级分类"></el-table-column>
      <el-table-column prop="classifyName3" label="二级分类"></el-table-column>
      <el-table-column prop="colorClassifyName" label="色彩"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.status === 1 ? "使用中" : "已下架" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="$router.push('/flower/detail?id=' + scope.row.id)"
            >编辑</el-button
          >
          <el-popconfirm title="确定要删除吗？" @confirm="del(scope.row.id)">
            <el-button type="text" slot="reference">删除</el-button>
          </el-popconfirm>
          <el-button type="text" @click="changeStatus(scope.row)">{{
            scope.row.status === 1 ? "下架" : "上架"
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="批量颜色、标签、命名" :visible.sync="mainShow">
      <el-form label-width="110px">
        <el-form-item label="分类">
          <el-cascader
            v-model="mainParams.classifyId"
            :options="labelList"
            :show-all-levels="false"
            :props="{
              children: 'child',
              value: 'id',
              label: 'name',
            }"
            @change="
              (val) => {
                mainParams.classifyId2 = val[0];
                mainParams.classifyId3 = val[1];
              }
            "
          ></el-cascader>
        </el-form-item>
        <el-form-item label="命名">
          <el-input v-model="mainParams.name"></el-input>
        </el-form-item>
        <el-form-item label="生长阶段">
          <el-select v-model="mainParams.growthStage" placeholder="请选择">
            <el-option label="长苗" value="长苗"> </el-option>
            <el-option label="成株" value="成株"> </el-option>
            <el-option label="开花" value="开花"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开花季节">
          <el-select
            v-model="mainParams.floweringStageArr"
            placeholder="请选择"
            multiple
            @change="
              (vals) => {
                mainParams.floweringStage = vals.join(',');
              }
            "
          >
            <el-option
              :label="item + '月'"
              :value="item"
              v-for="item in 12"
              :key="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色">
          <el-radio-group v-model="mainParams.colorClassifyId">
            <el-radio :label="item.id" v-for="item in colorList" :key="item.id">
              <span
                style="width: 10px; height: 10px; display: inline-block"
                :style="{ background: item.color }"
              ></span>
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="handleBatch(mainParams)"
            >确认</el-button
          >
          <el-button @click="reset('main')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="批量详细" :visible.sync="detailShow">
      <el-form label-width="110px">
        <el-form-item label="基础信息">
          <el-input v-model="detailParams.basicInfo" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="文化内涵">
          <el-input
            v-model="detailParams.connotation"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="日常使用搭配">
          <el-input
            v-model="detailParams.collocation"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="色彩搭配建议">
          <el-input
            v-model="detailParams.colorSuggestion"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="技术使用建议">
          <el-input
            v-model="detailParams.technicalSuggestion"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="寓意">
          <el-input v-model="detailParams.allegory" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="构图建议">
          <el-input
            v-model="detailParams.compositionSuggestion"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-button type="primary" @click="handleBatch(detailParams)"
            >确认</el-button
          >
          <el-button @click="reset('detail')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div style="margin-top: 15px">
      <el-button @click="mainShow = true">批量颜色、分类、命名</el-button>
      <el-button @click="handleBatch({ status: 1 })">上架</el-button>
      <el-button @click="handleBatch({ status: 2 })">下架</el-button>
      <el-button @click="detailShow = true">批量详细</el-button>
      <el-pagination
        background
        layout="prev, pager, next, sizes"
        :total="total"
        :current-page.sync="params.pageIndex"
        @current-change="getList"
        :page-sizes="[10, 20, 50, 100, 500]"
        @size-change="getList"
        :page-size.sync="params.pageSize"
        style="float: right"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainShow: false,
      mainParams: {
        classifyId2: "",
        classifyId3: "",
        classifyId: [],
        name: "",
        growthStage: "",
        floweringStageArr: [],
        colorClassifyId: "",
        floweringStage: "",
      },
      detailShow: false,
      detailParams: {
        basicInfo: "",
        connotation: "",
        collocation: "",
        colorSuggestion: "",
        technicalSuggestion: "",
        compositionSuggestion: "",
        allegory: "",
      },
      params: {
        time: [],
        pageIndex: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
        name: "",
        classifyId1: 1,
        classifyId2: "",
        status: "",
      },
      labelList: [],
      total: 0,
      list: [],
      multipleSelection: [],
      colorList: [],
    };
  },
  created() {
    this.getList();
    this.getLabelList();
  },
  methods: {
    reset(type) {
      if (type === "detail") {
        this.detailParams = {
          basicInfo: "",
          connotation: "",
          collocation: "",
          colorSuggestion: "",
          technicalSuggestion: "",
          compositionSuggestion: "",
          allegory: "",
        };
        this.detailShow = false;
      } else {
        this.mainParams = {
          classifyId2: "",
          classifyId3: "",
          classifyId: [],
          name: "",
          growthStage: "",
          floweringStageArr: [],
          colorClassifyId: "",
          floweringStage: "",
        };
        this.mainShow = false;
      }
    },
    handleBatch(params) {
      this.$confirm("确定要批量修改吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$ajax
            .post("/admin/materialManage/batchUpdate", {
              ids: this.multipleSelection.map((item) => {
                return item.id;
              }),
              ...params,
            })
            .then((res) => {
              this.$message.success("修改成功");
              this.reset("detail");
              this.reset("main");
              this.getList();
            });
        })
        .catch(() => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    changeStatus(item) {
      this.$ajax
        .post("/admin/materialManage/batchUpdate", {
          ids: [item.id],
          status: item.status === 1 ? 2 : 1,
        })
        .then((res) => {
          this.$message.success("修改成功");
          this.getList();
        });
    },
    del(id) {
      this.$ajax.get("/admin/materialManage/delete?id=" + id).then((res) => {
        this.$message.success("删除成功");
        this.getList();
      });
    },
    getList() {
      this.$ajax
        .post("/admin/materialManage/queryList", this.params)
        .then((res) => {
          this.list = res.list;
          this.total = res.total;
        });
    },
    getLabelList() {
      this.$ajax.post("/admin/classifyManage/queryAll").then((res) => {
        this.labelList = res.data.find((item) => item.id === 1).child;
        this.colorList = res.data.find((item) => item.id === 4).child;
      });
    },
  },
};
</script>

<style>
</style>